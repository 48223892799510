<template>
  <b-container fluid class="overflow-auto">
    <!-- Advertiser Interface controls -->
    <h5 class="my-3"><strong>ADVERTISERS</strong></h5>
    <a-row class="mb--1">
      <a-col :xl="4" :md="12" :xs="24">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select allowClear placeholder="Account Manager" style="width: 100%" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="selectedAdvertiser" @change="getFilteredAdvertisers">
          <a-select-option v-for="obj in user.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.FirstName }} {{ obj.LastName
            }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect v-model="perPage" :storageKey="'ortb-advertisers'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-advertisers-columns'" :options="fields"></columnSelect>
          <CreateButton   target="advertiser" />
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table :small="true" :bordered="true" :striped="true" :hover="true" show-empty stacked="md"
      :items="showAdvertisers" :fields="filteredFields" :current-page="currentPage" :per-page="perPage" :filter="filter"
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :tbody-tr-class="rowClass"
      @filtered="onFiltered">
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleAdvertiserStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(Country)="data">
        <div class="items-center"><img :src="matchIcon(countries, data.item.Country)" /> {{
        $store.getters['info/countryName'](data.item.Country) }}</div>
      </template>
      <template #cell(Id)="data">
        <div class="items-center">{{ data.item.Id }}</div>
      </template>
      <template #cell(Name)="data">
        <div class="items-center">{{ data.item.Name }}</div>
      </template>
      <template #cell(Description)="data">
        <div class="items-center">{{ data.item.Description }}</div>
      </template>
      <template #cell(Remotertbzones)="data">
        <div v-if="data.item.RemotertbzonesCount > 1">
          <b-link :to="redirectTo('advertiser-remotertbzones', { id: data.item.Id })"
            @click="showRemoteRTBZones(data.item, data.item.RemotertbzonesCount)" style="color:rgb(105, 151, 244)">{{
        data.item.RemotertbzonesCount }} zones</b-link>
        </div>
        <div v-else-if="data.item.RemotertbzonesCount === 1">
          <b-link :to="redirectTo('advertiser-remotertbzones', { id: data.item.Id })"
            @click="showRemoteRTBZones(data.item, data.item.RemotertbzonesCount)" style="color:rgb(105, 151, 244)">{{
        data.item.RemotertbzonesCount }} zone</b-link>
        </div>
        <div v-else-if="data.item.RemotertbzonesCount === 0">
          <b-link :to="redirectTo('create-remotertbzone-advertiserId', { advertiserId: data.item.Id })"
            @click="showRemoteRTBZones(data.item, data.item.RemotertbzonesCount)"
            style="color:rgb(105, 151, 244)">Create
            zone</b-link>
        </div>
        <div v-else>
        </div>
      </template>
      <template #cell(Remotevideozones)="data">
        <div v-if="data.item.RemotevideozonesCount > 1">
          <b-link :to="redirectTo('advertiser-remotevideozones', { id: data.item.Id })"
            @click="showRemoteVideoZones(data.item, data.item.RemotevideozonesCount)"
            style="color:rgb(105, 151, 244)">{{
        data.item.RemotevideozonesCount }} zones</b-link>
        </div>
        <div v-else-if="data.item.RemotevideozonesCount === 1">
          <b-link :to="redirectTo('advertiser-remotevideozones', { id: data.item.Id })"
            @click="showRemoteVideoZones(data.item, data.item.RemotevideozonesCount)"
            style="color:rgb(105, 151, 244)">{{
        data.item.RemotevideozonesCount }} zone</b-link>
        </div>
        <div v-else-if="data.item.RemotevideozonesCount === 0">
          <b-link :to="redirectTo('create-remotevideozone-advertiserId', { advertiserId: data.item.Id })"
            @click="showRemoteVideoZones(data.item, data.item.RemotevideozonesCount)"
            style="color:rgb(105, 151, 244)">Create
            zone</b-link>
        </div>
        <div v-else>
        </div>
      </template>
      <template #cell(DsppopfeedsCount)="data">
        <div v-if="data.item.DsppopfeedsCount > 1">
          <b-link :to="redirectTo('advertiser-dsppopfeeds', { id: data.item.Id })"
            @click="showDspPopFeeds(data.item, data.item.DsppopfeedsCount)"
            style="color:rgb(105, 151, 244)">{{
        data.item.DsppopfeedsCount }} feeds</b-link>
        </div>
        <div v-else-if="data.item.DsppopfeedsCount === 1">
          <b-link :to="redirectTo('advertiser-dsppopfeeds', { id: data.item.Id })"
            @click="showDspPopFeeds(data.item, data.item.DsppopfeedsCount)"
            style="color:rgb(105, 151, 244)">{{
        data.item.DsppopfeedsCount }} feed</b-link>
        </div>
        <!-- <div v-else-if="data.item.DsppopfeedsCount === 0">
           <b-link :to="redirectTo('create-dsppopfeed-advertiserId', { advertiserId: data.item.Id })"
            @click="showDspPopFeeds(data.item, data.item.DsppopfeedsCount)"
            style="color:rgb(105, 151, 244)">Create
            feed</b-link>
            <b-link :to="redirectTo('dsppopfeed-create-advertiser', { advertiserId: data.item.Id })" @click="showDspPopFeeds(data.item, data.item.DsppopfeedsCount)" style="color:rgb(105, 151, 244)"> Create feed</b-link>
        </div> -->
        <div v-else-if="data.item.DsppopfeedsCount === 0">
          <b-link :to="redirectTo('create-dsppopfeed-advertiserId', { advertiserId: data.item.Id })"
            @click="showDspPopFeeds(data.item, data.item.DsppopfeedsCount)"
            style="color:rgb(105, 151, 244)">Create
            zone</b-link>
        </div>
        <div v-else>
        </div>
      </template>
      <template #cell(Phone)="data">
        <div class="items-center">{{ data.item.Phone }}</div>
      </template>
      <template #cell(Skype)="data">
        <div class="items-center">{{ data.item.Skype }}</div>
      </template>
      <template #cell(Website)="data">
        <div class="items-center">{{ data.item.Website }}</div>
      </template>
      <template #cell(actions)="data">
        <router-link class="add-button" @click.stop
          :to="{ name: 'advertiser-update', params: { id: data.item.Id }, }">
          <b-button size="sm" class="btn-info mr-1" @click="editAdvertiser(data.item.Id)">
            <i class="fa fa-edit"></i>
          </b-button>
        </router-link>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
        (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
          perPage).toLocaleString()
      }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import staticCountryData from '@/resources/countries.json'

export default {
  components: {
    columnSelect,
    perPageSelect,
    CreateButton: () => import('@/components/custom/create-button/index.vue'),
  },
  data: function () {
    return {
      countries: staticCountryData,
      selectedAdvertiser: [],
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:70px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          headerTitle: 'ID',
          sortable: true,
          thStyle: 'width:50px',
          tdClass: 'text-right',
        },
        {
          key: 'Mail',
          label: 'Email',
          show: false,
          sortable: true,
          thStyle: 'width:120px',
        },
        {
          key: 'Username',
          label: 'Username',
          show: true,
          sortable: true,
          thStyle: 'width:120px',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          sortable: true,
        },
        {
          key: 'Description',
          label: 'Description',
          show: true,
          sortable: true,
        },
        {
          key: 'CampaignsCount',
          label: 'Campaigns',
          headerTitle: 'Campaigns',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:150px',
        },
        {
          key: 'Remotertbzones',
          label: 'Remote RTB Zones',
          headerTitle: 'Remote RTB Zones',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:150px',
        },
        {
          key: 'Remotevideozones',
          label: 'Remote Video Zones',
          headerTitle: 'Remote Video Zones',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:150px',
        },
        {
          key: 'DsppopfeedsCount',
          label: 'DSP Pop Feeds',
          headerTitle: 'DSP Pop Feeds',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:120px',
        },
        {
          key: 'Website',
          label: 'Website',
          show: true,
          sortable: true,
          thStyle: 'width:150px',
        },
        {
          key: 'Country',
          label: 'Country',
          show: true,
          sortable: true,
          thStyle: 'width:160px',
        },
        {
          key: 'StatsAuth',
          label: 'Stats Auth',
          headerTitle: 'Stats API endpoint authentication code',
          show: false,
          sortable: true,
          thStyle: 'width:110px',
        },
        {
          key: 'City',
          label: 'City',
          show: false,
          sortable: true,
          thStyle: 'width:100px',
        },
        {
          key: 'ZipCode',
          label: 'Zip Code',
          show: false,
          sortable: true,
          thStyle: 'width:100px',
        },
        {
          key: 'Address',
          label: 'Address',
          show: false,
          sortable: true,
          thStyle: 'width:200px',
        },
        {
          key: 'Phone',
          label: 'Phone',
          show: true,
          sortable: true,
          thStyle: 'width:140px',
        },
        {
          key: 'Skype',
          label: 'Skype',
          show: true,
          sortable: true,
          thStyle: 'width:130px',
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },

      ],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['info', 'advertiser', 'settings', 'user']),
    ...mapGetters('advertiser', ['getAdvertisers', 'getActiveAdvertisers']),
    showActive: {
      get: function () {
        return this.settings.showActiveAdvertisers
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActiveAdvertisers', value })
      },
    },
    showAdvertisers() {
      return this.showActive ? this.getActiveAdvertisers : this.getAdvertisers
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showAdvertisers.length
    },
  },
  methods: {
    getFilteredAdvertisers() {
      this.$store.dispatch('advertiser/LOAD_ADVERTISERS_FOR_USER', { AccManagerId: this.selectedAdvertiser })
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    redirectTo(route, data = null) {
      return this.$router.resolve({ name: route, params: data }).resolved.path
    },
    showRemoteRTBZones(advertiser, RemotertbzonesCount) {
      if (RemotertbzonesCount > 0) {
        this.$router.push({ name: 'advertiser-remotertbzones', params: { id: advertiser.Id } }).catch(() => { })
      } else {
        this.$router.push({ name: 'create-remotertbzone-advertiserId', params: { advertiserId: advertiser.Id } }).catch(() => { })
      }
    },
    showRemoteVideoZones(advertiser, RemotevideozonesCount) {
      if (RemotevideozonesCount > 0) {
        this.$router.push({ name: 'advertiser-remotevideozones', params: { id: advertiser.Id } }).catch(() => { })
      } else {
        this.$router.push({ name: 'create-remotevideozone-advertiserId', params: { advertiserId: advertiser.Id } }).catch(() => { })
      }
    },
    showDspPopFeeds(advertiser, DsppopfeedsCount) {
      if (DsppopfeedsCount > 0) {
        this.$router.push({ name: 'advertiser-dsppopfeeds', params: { id: advertiser.Id } }).catch(() => { })
      } else {
        this.$router.push({ name: 'create-dsppopfeed-advertiserId', params: { advertiserId: advertiser.Id } }).catch(() => { })
      }
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    showCampaigns(advertiser, CampaignsCount) {
      if (CampaignsCount > 0) {
        this.$router.push({ name: 'advertiser-campaigns', params: { id: advertiser.Id } }).catch(() => { })
      } else {
        this.$router.push({ name: 'create-campaign-advertiserId', params: { advertiserId: advertiser.Id } }).catch(() => { })
      }
    },
    editAdvertiser(id) {
      // this.$router.push('/display/advertiser/update/' + id)
      this.$router.push({ name: 'advertiser-update', params: { id: id } }).catch(() => { })
    },
    toggleAdvertiserStatus(advertiser) {
      const status = advertiser.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change advertiser status?',
        content: h => <div>{status} advertiser <strong>{advertiser.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = advertiser.Status === 0 ? 1 : 0
          this.$store.dispatch('advertiser/CHANGE_STATUS', {
            id: advertiser.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('advertiser/LOAD_ADVERTISERS')
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('user/LOAD_USERS')
  },
}
</script>
